import React from 'react';
import Layout from '../components/layout';

export default () => (
  <Layout title="Not Found" mainClass="not-found">
    <h1>404</h1>
    <p className="not-found__subhed">Whoops, we can’t find that page.</p>
    <p>
      <a href="/" className="btn">
        Back to Home
      </a>
    </p>
  </Layout>
);
